export default {
  PAGE_URL: 'https://www.hipvan.com',
  BASE_API_URL: "https://api-production.hipvan.com",
  BASE_LAC_LONG_QUAN_URL: "https://www.hipvan.com",
  CONTENT_MEDIA_API_URL: "https://media.hipvan.com",
  ANALYTICS_API_URL: "https://analytics.hipvan.com",
  ANALYTICS_API_APP_ID: "04ec8fe918134657b3aa4ecb7301e3e8",
  CDN_BASE_API_URL: "https://content-api.hipvan.com",
  CDN_X_API_KEY: "53fc6a40-1598-47d8-bdcb-e8b7a819a098",
  COUNTRY_CODE: "SG",
  COMPANY_NAME: "HipVan Pte. Ltd",
  HIPVAN_EMAIL: "wecare@hipvan.com",
  FACEBOOK_APP_ID: '210613868290651',
  STORE_COUNTRY: "Singapore",
  CURRENT_PLATFORM: "",
  CURRENT_SESSION_ID: "",
  CURRENT_USER_MAIL: "",
  GOOGLE_TAG_MANAGER_KEY: "GTM-WKT47W",
  PAGE_VIEW_KEY: "PAGE_VIEW_GTM",
  LAST_REAL_PAGE_VIEW_KEY: "LAST_REAL_PAGE_VIEW_GTM",
  FREE_SHIPPING_THRESHOLD: "$300",
  DEFAULT_PER_PAGE: "48",
  SEARCH_SUGGESTED_VALUE: [{"name":"Sofa Beds","url":"https://www.hipvan.com/furniture/sofa-beds?ref=search_topbar_popup"},{"name":"Cushions","url":"https://www.hipvan.com/furnishings/cushions-throws?ref=search_topbar_popup"},{"name":"Dining Chairs","url":"https://www.hipvan.com/furniture/dining-chairs?ref=search_topbar_popup"},{"name":"Beds","url":"https://www.hipvan.com/furniture/bed-frames?ref=search_topbar_popup"},{"name":"Mattresses","url":"https://www.hipvan.com/mattresses-by-hipvan?ref=search_topbar_popup"},{"name":"Sofas","url":"https://www.hipvan.com/furniture/sofas-sectionals?ref=search_topbar_popup"}],
  OFFICE_CHAIR_RENTAL_FORM_URL: "https://script.google.com/macros/s/AKfycbzZ-KMmlQXZwP0EGxS2bKT9EUdpi_REbHLUo0RhJQF4oT0X4P4/exec",
  CURRENCY: "SGD",
  CURRENCY_CODE: "SGD",
  CURRENCY_SYMBOL: "$",
  CASUAL_LOW_STOCK_ALERT: "1",
  URGENT_LOW_STOCK_ALERT: "5",
  BLOG_SPLASH_ARTICLES: ["x2ewr5ufpv4r", "aijn8k2rwmse", "mjpgx2gq8n8d"],
  ENABLE_GRAB_INSTALMENT: true,
  ENABLE_DBS_INSTALMENT: true,
  ENABLE_HOOLAH_INSTALMENT: true,
  ENABLE_ATOME_INSTALMENT: true,
  STRIPE_KEY: "pk_live_K0tIaJ8b1FpzHoXWGiNV1l2M",
  PAYMENT_TYPES: {"stripe":"stripe","paypal":"paypal","paydollar":"paydollar","free_of_charge":"free_of_charge","braintree":"braintree","hoolah":"hoolah","grabpay":"grabpay","grab_instalment":"grab_instalment","paynow":"paynow","two_c_two_p":"two_c_two_p","tctp_cc":"tctp_cc"},
  FLAG_SHIP_DISCOUNT_CODE: "FLAGSHIP",
  SOURCE_ENV: "production",
  DELIVERY_BASE_OFFER: "free standard delivery",
  LOYALTY_POINTS_MIN_QTY_ALLOW_REDEEM: "6",
  LOYALTY_POINTS_FEATURES: [{"label":"Points per $1 spent","type":"pts","green":1,"gold":1.5},{"label":"Redeem rewards","type":"star","green":1,"gold":1},{"label":"Free Standard Delivery for all orders","type":"star","green":0,"gold":1},{"label":"Early access to deals","type":"star","green":0,"gold":1},{"label":"Exclusive events","type":"star","green":0,"gold":1}],
  LOYALTY_MODULE_ACTIVATE: false,
  REFERRAL_MODULE_ACTIVATE: true,
  APP_URL_VIA_APPSFLYER: "https://hipvan.onelink.me/3494037219",
  SHARED_WISHLIST_SEO_TITLE: "Shared Wishlist | HipVan",
  DEFAULT_SEO_TITLE: "Buy Furniture Online in Singapore | HipVan",
  DEFAULT_SEO_OG_IMAGE: "https://hipvan-images-production.s3.amazonaws.com/hipvan-og-image.jpg",
  KLEVU_SEARCH_URL: "https://eucs31.ksearchnet.com/cs/v2/search",
  KLEVU_API_KEY: "klevu-166969059333615828",
  GOOGLE_OAUTH_CLIENT_ID: "445441345941-caim13l77dst4he4chaamoe0b8p8reqq.apps.googleusercontent.com",
  KLEVU_TAXON_LANE_ID: "k-0b5c858c-672a-4d04-ac77-6005f55bdd83",
  DEFAULT_EXTERNAL_ID: "74234e98afe7498fb5daf1f36ac2d78acc339464f950703b8c019892f982b90b",
  RECOMBEE_ECOM_DB: "hipvan-weijie-hipvan-ecommerce-search-prod",
  RECOMBEE_ECOM_TOKEN: "5jKA1A8BPzhraCzAvCkgvtL1EDmU6Oe16lsLKjRlxVzHOqDUqcwDW5hp4NIuKkbd",
  RECOMBEE_ECOM_REGION: "ap-se",
  KLEVU_ANALYTIC_URL: "https://stats.ksearchnet.com/analytics",
  FIREBASE_PROJECT_ID: "",
  SENTRY_DSN: "https://dfd08e6567e4eb9ef38c169813c0eeba@o4508329039888384.ingest.us.sentry.io/4508437053112320"
}
